import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/match/:id',
    name: 'Match',
    component: () => import('../views/FootballMatch.vue'),
  },
  {
    path: '/matchThread/:matchId/:threadId',
    name: 'MatchThread',
    component: () => import('../views/MatchThread.vue'),
  },
  {
    path: '/profile/:username',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/refereeRanking',
    name: 'RefereeRanking',
    component: () => import('../views/RefereeRanking.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
