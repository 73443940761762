import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import useVuelidate from '@vuelidate/core';
import Toast from 'vue-toastification';
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import ConfirmationService from 'primevue/confirmationservice';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'bootswatch/dist/darkly/bootstrap.min.css';
import 'bootstrap';
import 'primevue/resources/themes/md-dark-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vue-toastification/dist/index.css';

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(useVuelidate)
  .use(Toast)
  .use(PrimeVue)
  .component('Dialog', Dialog)
  .use(Dialog)
  .use(ConfirmationService)
  .mount('#app');
