import { createStore } from 'vuex';

export default createStore({
  state: {
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    user: localStorage.getItem('user') || '',
    ip: 'refstats.duckdns.org',
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    getToken(state) {
      state.token = localStorage.getItem('token');
      return state.token;
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', user);
    },
    getUser(state) {
      localStorage.getItem('user');
      return state.user;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
    },
    getRefreshToken(state) {
      state.refreshToken = localStorage.getItem('refreshToken');
      return state.refreshToken;
    },
  },
  actions: {
    setToken({ commit }, token) {
      localStorage.setItem('token', token);
      commit('setToken', token);
    },
    getToken({ commit }) {
      commit('getToken');
      return commit('getToken');
    },
    setUser({ commit }, user) {
      localStorage.setItem('user', user);
      commit('setUser', user);
    },
    getUser({ commit }) {
      commit('getUser');
      return commit('getUser');
    },
    setRefreshToken({ commit }, refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
      commit('setRefreshToken', refreshToken);
    },
    getRefreshToken({ commit }) {
      commit('getRefreshToken');
      return commit('getRefreshToken');
    },
  },
  modules: {
  },
});
